<template>
  <AppSidebar fixed>
    <SidebarHeader/>
    <SidebarForm/>
    <SidebarNav :navItems="nav"></SidebarNav>
    <SidebarFooter/>
    <SidebarMinimizer/>
  </AppSidebar>
</template>
<script>
import { SidebarToggler, Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, SidebarNav } from '@coreui/vue'

export default {
  name: 'Sidebar',
  components: {
    AppSidebar,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer
  },
  data () {
    return {
      nav: [
        {
          name: 'Intake Requests',
          url: '/dashboard',
          icon: 'icon-speedometer'
          // badge: {
          //   variant: 'primary',
          //   text: 'NEW'
          // }
        }
      ]
    }
  },
  computed: {
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched.filter((route) => route.name || route.meta.label )
    }
  }
}
</script>
